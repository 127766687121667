import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Paulina = () => {
    return (
        <Layout>
            <Seo title="Paulina"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Paulina Benitez</h1>
                        <p className="member-intro_text_description">Dyplomowana instruktorka Pilatesu, tańca i stretchingu
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src="../images/poza_paulina.jpg" 
                            placeholder="blurred"
                            alt="Instruktorka Paulina Benitez" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O PAULINIE</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Dyplomowana instruktorka Pilatesu, tańca i Stretchingu, lingwista.</h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Paulina pracuje metodą Pilates w nurcie współczesnym, czyli sportowo-rehabilitacyjnym.</p>
                            <p className="member-hero-paragraph_info-text">Ukończyła kilka kursów metody Pilates- w tym szkoleń z zakresu treningów oddechu oraz mięśni dna miednicy. Od 20 lat jest związana z różnymi formami ruchu – jako nastolatka przez ponad 5 lat trenowała sztuki walki a od 15 lat rozwija się w różnych stylach tańca, zgłębiając jednocześnie techniki świadomej pracy z ciałem. Szczególnie interesuje ją trening terapeutyczny w nurcie pilatesu nowoczesnego z elementami pracy z powięzią i stretchingu. W pracy z ciałem inspiruje się również tańcem a także takimi metodami jak Barre, Gyrokinesis, Metoda Franklina oraz Anatomy Trains in Motion, Terapia Tańcem i Ruchem.
                            </p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                            <li>Francuski/ French</li>
                            <li>Hiszpański/ Spanish</li>
                            <li>Japoński/ Japanese</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Pilates</li>
                                <li>Stretching</li>
                                <li>Trening mięśni dna miednicy</li>
                                <li>Taniec</li>
                                <li>Barre</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Indywidualnie</li>
                                <li>W duetach</li>
                                <li>W minigrupach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Reformer</li>
                            <li>Reformer z Wieżą</li>
                            <li>Wunda Chair, Combo Chair</li>
                            <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Kobiety w ciąży</li>
                                <li>Kobiety po porodzie</li>
                                <li>Dzieci i młodzież</li>
                                <li>Osoby z wadami postawy</li>
                                <li>Osobami z dużymi dolegliwościami bólowymi</li>
                                <li>Osoby po wypadkach, urazach, operacjach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_info_link-container">
                            <Link to="/zapisy" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</Link>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Paulina;
